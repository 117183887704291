import React from "react";
import { Slide } from "react-reveal";
import smalldog from "../../assets/images/smallimages/dog.png";
import d1 from "../../assets/images/gallery/1.jpg";
import d2 from "../../assets/images/gallery/2.jpg";
import d3 from "../../assets/images/gallery/3.jpg";
import d4 from "../../assets/images/gallery/4.jpg";
import GallerySlideCard from "../reusables/GallerySlideCard";

const AtWork = () => {
  return (
    <section className="section dogcred-bg section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="width-600 margin-auto center">
              <p className="section-name boldTitle dark-yellow">GALLERY</p>
              <Slide top>
                <div className="section-head">
                  <h2 className="section-title dark">SEE US AT WORK</h2>
                  <img
                    src={smalldog}
                    alt="Small Dog"
                    className="small-image auto"
                  />
                </div>
              </Slide>
            </div>
            <div className="section-body section-spacing-20">
              <div className="gallery-slider-container slider-container slider">
                <ul className="slide-track slider-items-wrap trustedby-items-wrapper">
                  <GallerySlideCard img={d1} />
                  <GallerySlideCard img={d2} />
                  <GallerySlideCard img={d3} />
                  <GallerySlideCard img={d4} />
                  <GallerySlideCard img={d2} />
                  <GallerySlideCard img={d3} />
                  <GallerySlideCard img={d1} />
                  <GallerySlideCard img={d4} />
                </ul>
              </div>
            </div>
            <div className="section-footer buttons-container flex justify-center section-spacing-20 no-padding-bottom">
              <a href="/gallery" className="button boldTitle subcta-green">
                View Gallery
              </a>
            </div>
            <div className="center">
              <img
                src={smalldog}
                alt="Small Dog"
                className="small-image auto"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AtWork;
