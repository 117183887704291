import React from "react";
import BreedCard from "../reusables/BreedCard";
import d3 from "../../assets/images/content/d3.jpg";
import rot from "../../assets/images/breeds/rot.jpg";
import gswiss from "../../assets/images/breeds/gswiss.jpg";
import malt from "../../assets/images/breeds/maltese1.jpg";
import belg from "../../assets/images/breeds/belg.jpg";
import dasch from "../../assets/images/breeds/dasch.jpg";
import dob from "../../assets/images/breeds/dob.jpg";
import beag from "../../assets/images/breeds/beag1.jpg";
import dshef from "../../assets/images/breeds/dshef.jpg";
import gsheph from "../../assets/images/breeds/gsheph.jpg";

const BreedsBody = () => {
  return (
    <section className="section section-spacing-100 dogs-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="breeds-container flex">
              <BreedCard
                img={rot}
                breed="ROTTWEILER"
                roles="Family Protector, Search, Rescue Operations"
              >
                <p>
                  Originating from Germany, renowned for its strength, loyalty,
                  and versatility. Often hailed as excellent guardians and
                  working dogs. Their intelligence, coupled with a calm
                  disposition, makes them adaptable companions, excelling in
                  roles ranging from family protector to search and rescue
                  operations.
                </p>
              </BreedCard>
              <BreedCard
                img={gswiss}
                breed="SWISS GERMAN SHEPHERD"
                roles="Family Companion"
              >
                <p>
                  Also known as the Berger Blanc Suisse, an elegant and
                  intelligent breed. Recognized for its striking white coat,
                  this medium to large-sized dog is known for their loyalty,
                  versatility, and friendly disposition. Originally developed
                  from the German Shepherd, it has a gentle temperament.
                </p>
              </BreedCard>
              <BreedCard img={malt} breed="MALTESE" roles="Family Companion">
                <p>
                  Small and elegant toy breed known for its long, silky white
                  coat and friendly demeanor. Originating from Malta, this
                  charming dog is characterized by its playful personality,
                  alert expression, and affectionate nature. Despite its size,
                  the Maltese is often confident and enjoys being the center of
                  attention.
                </p>
              </BreedCard>
              <BreedCard
                img={belg}
                breed="BELGIAN MALINOIS"
                roles="Police Work, Search &amp; Rescue, Dedicated
                  Family Companion"
              >
                <p>
                  Highly intelligent and versatile herding breed known for its
                  agility and trainability. Originally developed in Belgium for
                  herding and guarding. Recognized for their strong work ethic,
                  loyalty, and protective instincts. Comes in large to medium
                  size, has a short and fawn-colored coat.
                </p>
              </BreedCard>
              <BreedCard
                img={dasch}
                breed="DACHSHUND"
                roles="Hunting, Family Protector"
              >
                <p>
                  Also known as the "wiener dog” or Sausage Dog. Known for its
                  long body, short legs, and distinctive elongated shape.
                  Originally bred in Germany for hunting, they come in three
                  coat types: smooth, long-haired, and wire-haired. Have a
                  playful nature, loyalty, and sometimes stubborn demeanor.
                </p>
              </BreedCard>
              <BreedCard
                img={dob}
                breed="DOBERMAN"
                roles="Guarding, Affectionate Family Companion"
              >
                <p>
                  Medium-to-large breed known for its sleek, muscular build and
                  alert demeanor. Originally developed in Germany as a guard
                  dog. Intelligent, loyal, and trainable. They have short,
                  smooth coats that come in various colors, and their
                  distinctive appearance is marked by cropped ears and a docked
                  tail.
                </p>
              </BreedCard>
              <BreedCard
                img={beag}
                breed="BEAGLE"
                roles="Scent Detection, Family Pet"
              >
                <p>
                  Known for its friendly demeanor and keen sense of smell.
                  Originating from England, Beagles have a distinctive
                  appearance with a short coat, long ears, and a tail often
                  carried high. They are energetic, curious, and make excellent
                  family pets.
                </p>
              </BreedCard>
              <BreedCard
                img={dshef}
                breed="DUTCH SHEPHERD"
                roles="Herding, Protection Work"
              >
                <p>
                  Known for its versatility and intelligence. Hailing from the
                  Netherlands, typically has a well-balanced and athletic build
                  with a brindle coat, erect ears, and a bushy tail. Highly
                  trainable, loyal, and make excellent working dogs.Prized
                  companions for active individuals or families.
                </p>
              </BreedCard>
              <BreedCard
                img={gsheph}
                breed="GERMAN SHEPHERD"
                roles="Police &amp; Military Work, Search &amp; Rescue, Guide, Family Pet"
              >
                <p>
                  Large and versatile, renowned for its intelligence, loyalty,
                  and strength. Originating from Germany, have a distinct double
                  coat and are often tan with a black saddle. German Shepherds
                  are commonly employed in various roles, such as police and
                  military work, search and rescue, and as guide dogs.
                </p>
              </BreedCard>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BreedsBody;
