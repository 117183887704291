import React from "react";
import map from "../../../assets/images/icons/map.svg";
import phone from "../../../assets/images/icons/phone.svg";
import email from "../../../assets/images/icons/email.svg";
import fb from "../../../assets/images/icons/facebook.svg";
import tw from "../../../assets/images/icons/twitter.svg";
import wa from "../../../assets/images/icons/whatsapp.svg";
import ig from "../../../assets/images/icons/instagram.svg";
import li from "../../../assets/images/icons/linkedin.svg";

const TopBar = () => {
  return (
    <section className="topbar">
      <div className="container">
        <div className="large">
          <div className="topbar-contents">
            <div className="top-contacts flex">
              <p className="top-contacts-item">
                <img src={map} alt="Map Icon" />
                <span>Seeta, Kasangati, Kampala Uganda</span>
              </p>
              <p className="top-contacts-item mid-item">
                <img src={email} alt="Email Icon" />
                <span>GHK9Academy@gmail.com</span>
              </p>
              <p className="top-contacts-item">
                <img src={phone} alt="Call Icon" />
                <span>+256 (742) 999 000</span>
              </p>
            </div>
            <div className="top-social flex">
              {/*
              <a href="https://www.instagram.com/ghsfacademy/">
                <img src={ig} alt="IG Icon" />
              </a>
              <a
                href="https://www.linkedin.com/company/great-habib-smart-academy/"
                className="mid-item"
              >
                <img src={li} alt="Linkedin Icon" />
              </a>

              <a href="https://wa.me/256756610041">
                <img src={wa} alt="WhatsApp Icon" />
              </a>
             */}

              <a
                href="https://twitter.com/greathabibk9aca"
                className="mid-item"
              >
                <img src={tw} alt="Twitter Icon" />
              </a>
              <a href="https://www.facebook.com/greathabibk9academy">
                <img src={fb} alt="Facebook Icon" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBar;
