import React, { useState } from "react";
import logo from "../../../assets/images/logo/logo.png";
import bars from "../../../assets/images/icons/bars.svg";
import close from "../../../assets/images/icons/close.svg";

const NavBar = () => {
  const [showParent, setShowParent] = useState(false);
  return (
    <section className="navbar">
      <div className="container">
        <div className="large">
          <div className="nav-contents flex">
            <a className="brand-side" href="/">
              <img src={logo} alt="Great Habib K9 Academy logo" />
            </a>
            <nav className="menu-side relative">
              <label
                onClick={() => setShowParent(true)}
                htmlFor="toggle-responsive-menu"
                className="nav-label bold-title"
              >
                <img src={bars} alt="Menu" />
              </label>
              <input type="checkbox" id="toggle-responsive-menu" />
              <div
                onClose={() => setShowParent(false)}
                showParent={showParent}
                className={`menu-side-inner ${showParent ? "showParent" : ""}`}
              >
                <div className="hide-menu">
                  <button
                    onClick={() => setShowParent(false)}
                    className="hide-menu-btn button"
                  >
                    <img src={close} alt="Close Menu" />
                  </button>
                </div>
                <ul className="main-menu">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about-us">About Us</a>
                  </li>
                  <li>
                    <a href="/breeds">Our Breeds</a>
                  </li>
                  <li>
                    <a href="/our-services">Our Services</a>
                  </li>
                  <li>
                    <a href="/contact-us">Contact Us</a>
                  </li>
                  <li className="hide-responsive">
                    <a href="/price-list">Price List</a>
                  </li>
                  <li className="hide-responsive">
                    <a href="/gallery">Gallery</a>
                  </li>
                  <li className="hide-responsive">
                    <a href="/shop">Academy Shop</a>
                  </li>
                </ul>
                <a
                  href="https://form.jotform.com/ghk9academy/animal-enrollment-form"
                  className="button nav-cta boldTitle uppercase"
                >
                  Enroll Your Dog
                </a>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NavBar;
