import React from "react";
import logo from "../../assets/images/logo/logo.png";
import map from "../../assets/images/icons/map.svg";
import phone from "../../assets/images/icons/phone.svg";
import email from "../../assets/images/icons/email.svg";
import clock from "../../assets/images/icons/clock.svg";

const Footer = () => {
  return (
    <section className="footer section-spacing-20 no-padding-bottom dark-bg">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content section-spacing-50">
              <div className="footer-menu flex">
                <div className="footer-menu-item about-footer">
                  <img src={logo} className=" box-shadow" alt="Logo" />
                  <div className="footer-body">
                    <p>
                      We are passionate about nurturing wellbehaved and happy
                      dogs while strengthening the bond between pets and their
                      owners. Our journey began with a shared love for dogs and
                      a commitment to providing exceptional canine education and
                      behavior modification services.
                    </p>
                  </div>
                  <div className="footer-subscribe section-spacing-20">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button subcta-lightgreen width-100 boldTitle"
                      href="https://form.jotform.com/greathabibfarmacademy/subscribe-form"
                    >
                      Subscribe To Our Newsletter
                    </a>
                  </div>
                </div>
                <div className="footer-menu-item">
                  <h3 className="footer-menu-title">Contact Us</h3>
                  <ul className="footer-nav contact-us">
                    <li>
                      <img src={map} alt="Map Icon" />
                      <span>Seeta, Kasangati, Kampala Uganda</span>
                    </li>
                    <li>
                      <img src={clock} alt="Clock icon" />
                      <span>Sun-Mon 09:00AM - 04:00PM</span>
                    </li>
                    <li>
                      <img src={email} alt="Email" />
                      <span>contact@ghk9academy.com</span>
                    </li>
                    <li>
                      <img src={phone} alt="Phone" />
                      <span>+256 (742) 999 000</span>
                    </li>
                  </ul>
                </div>
                <div className="footer-menu-item">
                  <h3 className="footer-menu-title">Quick Links</h3>
                  <ul className="footer-nav">
                   
                    <li>
                      <a href="/gallery">Gallery</a>
                    </li>
                    <li>
                      <a href="/resources">Resources</a>
                    </li>
                    <li>
                      <a href="/breeds">Our Breeds</a>
                    </li>
                    <li>
                      <a href="/our-services">Our Services</a>
                    </li>
                  </ul>
                </div>

                <div className="footer-menu-item">
                  <h3 className="footer-menu-title">Social Media</h3>
                  <ul className="footer-nav">
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://twitter.com/greathabibk9aca"
                      >
                        Twitter
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/greathabibk9academy"
                      >
                        Facebook
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href="#">
                        Instagram
                      </a>
                    </li>
                    <li>
                      <a target="_blank" rel="noopener noreferrer" href="#">
                        WhatsApp
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="copyright padding-top-20 padding-bottom-8 center ntuna-yellow">
              <p>&copy; 2023 GREAT HABIB K9 ACADEMY, All Rights Reserved</p>
            </div>
            <div className="designer section-spacing-10 uppercase">
              <div className="design-inner white center">
                <p className=" rich-small">
                  Website by{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://ntuna.com"
                    className="light-green"
                  >
                    NTUNA.COM
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
