import React from "react";
import Redirect404 from "../components/404/Redirect404";
import { Helmet } from "react-helmet";

export default function PageNotFound404() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.ghk9academy.com/404" />
        <title>Great Habib K9 Academy | Page Not Found</title>
      </Helmet>
      <Redirect404 />
    </>
  );
}
