import React from "react";
import { Helmet } from "react-helmet";
import AboutHome from "../components/home/AboutHome";
import HomeHero from "../components/home/HomeHero";
import OurServices from "../components/home/OurServices";
import Partners from "../components/home/Partners";
import GreatHabibResults from "../components/reusables/GreatHabibResults";

export default function Home() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.ghk9academy.com" />
      </Helmet>
      <HomeHero />
      <AboutHome />
      <OurServices />
      <GreatHabibResults />
      <Partners />
    </>
  );
}
