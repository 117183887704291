import React from "react";

const GallerySlideCard = (props) => {
  return (
    <li className="slide">
      <div className="slide-image-container slide-content-container">
        <img src={props.img} alt="Dog Training"  className=" box-shadow"/>
      </div>
    </li>
  );
};

export default GallerySlideCard;
