import React from "react";
import GalleryCard from "../reusables/GalleryCard";
import d1 from "../../assets/images/gallery/1.jpg";
import d2 from "../../assets/images/gallery/2.jpg";
import d3 from "../../assets/images/gallery/3.jpg";
import d4 from "../../assets/images/gallery/4.jpg";
import d5 from "../../assets/images/gallery/5.jpg";
import d6 from "../../assets/images/gallery/6.jpg";
import d7 from "../../assets/images/gallery/7.jpg";
import d8 from "../../assets/images/gallery/8.jpg";
import d9 from "../../assets/images/gallery/9.jpg";
import d10 from "../../assets/images/gallery/10.jpg";
import d11 from "../../assets/images/gallery/11.jpeg";
import d12 from "../../assets/images/gallery/12.jpeg";
import d13 from "../../assets/images/gallery/13.jpeg";
import d14 from "../../assets/images/gallery/14.jpeg";
import d15 from "../../assets/images/gallery/15.jpeg";
import d16 from "../../assets/images/gallery/16.jpeg";
import d17 from "../../assets/images/gallery/17.jpeg";
import d18 from "../../assets/images/gallery/18.jpeg";
import d19 from "../../assets/images/gallery/19.jpeg";
import d20 from "../../assets/images/gallery/20.jpeg";
import d21 from "../../assets/images/gallery/21.jpeg";
import d22 from "../../assets/images/gallery/22.jpeg";
import d23 from "../../assets/images/gallery/23.jpeg";

const GalleryBody = () => {
  return (
    <section className=" section-spacing-80 no-padding-top">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content white-bg relative margin-auto border-radius-2">
              <div className="section-body">
                <div className="padding-16 center">
                  <div className="gallery-container">
                    <GalleryCard
                      galleryImage={d1}
                      galleryImageCaption="Dog Training"
                    />
                    <GalleryCard
                      galleryImage={d2}
                      galleryImageCaption="Dog Training"
                    />
                    <GalleryCard
                      galleryImage={d3}
                      galleryImageCaption="Dog Training"
                    />
                    <GalleryCard
                      galleryImage={d4}
                      galleryImageCaption="Dog Training"
                    />
                    <GalleryCard
                      galleryImage={d5}
                      galleryImageCaption="Dog Training"
                    />
                    <GalleryCard
                      galleryImage={d3}
                      galleryImageCaption="Dog Training"
                    />
                    <GalleryCard
                      galleryImage={d6}
                      galleryImageCaption="Dog Training"
                    />
                    <GalleryCard
                      galleryImage={d7}
                      galleryImageCaption="Dog Training"
                    />
                    <GalleryCard
                      galleryImage={d8}
                      galleryImageCaption="Dog Training"
                    />
                    <GalleryCard
                      galleryImage={d9}
                      galleryImageCaption="Dog Training"
                    />
                    <GalleryCard
                      galleryImage={d10}
                      galleryImageCaption="Dog Training"
                    />
                    <GalleryCard galleryImage={d11} />
                    <GalleryCard galleryImage={d12} />
                    <GalleryCard galleryImage={d13} />
                    <GalleryCard galleryImage={d14} />
                    <GalleryCard galleryImage={d15} />
                    <GalleryCard galleryImage={d16} />
                    <GalleryCard galleryImage={d17} />
                    <GalleryCard galleryImage={d18} />
                    <GalleryCard galleryImage={d19} />
                    <GalleryCard galleryImage={d20} />
                    <GalleryCard galleryImage={d21} />
                    <GalleryCard galleryImage={d22} />
                    <GalleryCard galleryImage={d23} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GalleryBody;
