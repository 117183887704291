import React from "react";
import { Slide } from "react-reveal";
import smalldog from "../../assets/images/smallimages/dog.png";
import Services from "../reusables/Services";

const OurServices = () => {
  return (
    <section className="section section-spacing-100 dog-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="width-600 margin-auto center">
              <p className="section-name boldTitle dark-yellow">What We do</p>
              <Slide top>
                <div className="section-head">
                  <h2 className="section-title dark">What We Offer</h2>
                  <img
                    src={smalldog}
                    alt="Small Dog"
                    className="small-image auto"
                  />
                  <p className="section-subtitle margin-top-bottom-10">
                    You’ll enjoy knowing our dedicated team will do whatever is
                    needed to keep your pets happy, trained, healthy and safe
                    for you and everyone close to them.
                  </p>
                </div>
              </Slide>
            </div>
            <div className="section-body section-spacing-100">
              <Services />
            </div>
            
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurServices;
