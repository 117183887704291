import React from "react";
import { Slide } from "react-reveal";
//import t1 from "../../assets/images/team/t1.jpg";
import t4 from "../../assets/images/team/MASAGAZI-ENOCKA--HEAD-OF-DISCIPLINARY.png";
import t5 from "../../assets/images/team/MUGERWA-SHARIF--WELFARE-&-PUPPY-DEVELOPMENT-TRAINER.png";
import t6 from "../../assets/images/team/WALUSIMBI-SHAFIQ--MANAGER-HEAD-OF-K9.png";
import t7 from "../../assets/images/team/AWOR-RICHARD---FOOD-SUPPLY-,-TRAINER.jpg";
import TeamCard from "./TeamCard";
import smalldog from "../../assets/images/smallimages/dog.png";

const Team = () => {
  return (
    <section className="whitedot-bg section-spacing-50">
      <Slide top>
        <div className="section-head center">
          <h2 className="section-title dark">MEET OUR TEAM</h2>
          <img src={smalldog} alt="Small Dog" className="small-image auto" />
        </div>
      </Slide>
      <div className="section-body section-spacing-50">
        <ul className="team-container">
          {/*
          <TeamCard pic={t1} name="Shubi Nantege" title="Director" />
          */}
          <TeamCard
            pic={t6}
            name="WALUSIMBI SHAFIQ"
            title="MANAGER HEAD OF K9"
          />
          <TeamCard
            pic={t4}
            name="MASAGAZI ENOCKA"
            title="HEAD OF DISCIPLINARY"
          />
          <TeamCard
            pic={t5}
            name="MUGERWA SHARIF"
            title="WELFARE &amp; PUPPY DEVELOPMENT TRAINER"
          />
          <TeamCard pic={t7} name="AWOR RICHARD" title="FOOD SUPPLY, TRAINER" />
        </ul>
      </div>
    </section>
  );
};

export default Team;
