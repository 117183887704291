import React from "react";
import Slider from "react-slick";
import { Slide } from "react-reveal";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings } from "../reusables/carousel";
import CarCard from "../reusables/CarCard";
import logo from "../../assets/images/logo/logo.png";
import d5 from "../../assets/images/content/d5.jpg";
import d2 from "../../assets/images/content/d2.jpg";
import d13 from "../../assets/images/content/d13.jpg";
import d15 from "../../assets/images/content/d15.jpg";

const HomeHero = () => {
  return (
    <section className="hero">
      <div className="carous">
        <div className="slider-items-container center-slide-arrows">
          <Slider {...settings}>
            <CarCard bgImg={d5}>
              <Slide top>
                <div className="hero-content-container welcome">
                  <div className="hero-content-inner centered">
                    <div className="hero-body centered-inner">
                      <div className="hero-body-head">
                        <img src={logo} alt="Logo" className="hero-logo" />
                        <h1 className=" margin-bottom-20">
                          <span className="block">Welcome to</span> Great Habib
                          k9 Academy
                        </h1>
                        <p className="boldTitle">Your home of trained dogs</p>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={d2}>
              <Slide top>
                <div className="hero-content-container">
                  <div className="hero-content-inner centered">
                    <div className="hero-body centered-inner">
                      <div className="hero-body-head">
                        <h1 className=" margin-bottom-20">
                          Wish your dog was focused and listened to you?
                        </h1>
                      </div>
                      <div className="hero-body-footer margin-top-20">
                        <a
                          href="https://form.jotform.com/ghk9academy/animal-enrollment-form"
                          className="button subcta-yellow boldTitle uppercase"
                        >
                          Let's Train It &rarr;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={d13}>
              <Slide top>
                <div className="hero-content-container">
                  <div className="hero-content-inner centered">
                    <div className="hero-body centered-inner">
                      <div className="hero-body-head">
                        <h1 className=" margin-bottom-20">
                          Have a stressed dog who barks excessively?
                        </h1>
                      </div>
                      <div className="hero-body-footer margin-top-20">
                        <a
                          href="https://form.jotform.com/ghk9academy/animal-enrollment-form"
                          className="button subcta-yellow boldTitle uppercase"
                        >
                          Let's Train It &rarr;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
            <CarCard bgImg={d15}>
              <Slide top>
                <div className="hero-content-container">
                  <div className="hero-content-inner centered">
                    <div className="hero-body centered-inner">
                      <div className="hero-body-head">
                        <h1 className=" margin-bottom-20">
                          Have a New Puppy and want It Trained right?
                        </h1>
                      </div>
                      <div className="hero-body-footer margin-top-20">
                        <a
                          href="https://form.jotform.com/ghk9academy/animal-enrollment-form"
                          className="button subcta-yellow boldTitle uppercase"
                        >
                          Let's Train It &rarr;
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Slide>
            </CarCard>
          </Slider>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
