import React from "react";
import { Helmet } from "react-helmet";
import AboutBody from "../components/about/AboutBody";
import GreatHabibResults from "../components/reusables/GreatHabibResults";
import PageHero from "../components/reusables/hero/PageHero";

export default function About() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.ghk9academy.com/about-us" />
        <meta name="description" content="Your Home of Trained Dogs" />
        <meta property="og:description" content="Your Home of Trained Dogs" />
        <meta property="og:title" content="About Us - Great Habib K9 Academy" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1703154000/banner_i6dkvh.jpg"
        />

        <meta
          name="twitter:title"
          content="About Us - Great Habib K9 Academy"
        />
        <meta
          name="twitter:text:title"
          content="About Us - Great Habib K9 Academy"
        />
        <meta name="twitter:description" content="Your Home of Trained Dogs" />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1703154000/banner_i6dkvh.jpg"
        />
        <title>About Us - Great Habib K9 Academy</title>
      </Helmet>
      <PageHero
        classes="doggy-bg"
        titleClasses="title uppercase yellow"
        title="About GHK9A"
      />
      <AboutBody />
      <GreatHabibResults />
    </>
  );
}
