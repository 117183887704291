import React from "react";
import { Slide } from "react-reveal";
import dog1 from "../../assets/images/gallery/7.jpg";
import dog2 from "../../assets/images/gallery/8.jpg";
import dog3 from "../../assets/images/gallery/9.jpg";
import dog4 from "../../assets/images/gallery/10.jpg";
import dog5 from "../../assets/images/gallery/7.jpg";
import dog6 from "../../assets/images/gallery/9.jpg";

const Services = () => {
  return (
    <div>
      <div className="aligned-content-container flex">
        <Slide left>
          <div className="right-aligned-content">
            <div className="aligned-item flex">
              <img src={dog1} alt="Dog Head" />
              <div>
                <h3>Boarding &amp; Grooming</h3>
                <p>
                  Your dog will be well taken care of and kept safe when away.
                </p>
              </div>
            </div>
            <div className="aligned-item flex pushed">
              <img src={dog2} alt="Dog Head" />
              <div>
                <h3>Agility Training</h3>
                <p>
                  This will help your dog nuild lean muscle, strengthen joints
                  and improve his/her cordination.
                </p>
              </div>
            </div>
            <div className="aligned-item flex pushed">
              <img src={dog3} alt="Dog Head" />
              <div>
                <h3>Basic Obidience Training</h3>
                <p>
                  This informs your dog of your house pet rules and also set
                  ground rules for good behaviour.
                </p>
              </div>
            </div>
            <div className="aligned-item flex">
              <img src={dog4} alt="Dog Head" />
              <div>
                <h3>Behaviour Modification Training</h3>
                <p>
                  This will help cultivate a trust-based relationship with your
                  dog and promote a harmonius living environment.
                </p>
              </div>
            </div>
          </div>
        </Slide>
        <div className="spacing"></div>
        <Slide right>
          <div className="left-aligned-content">
            <div className="aligned-item flex">
              <img src={dog5} alt="Dog Head" />
              <div>
                <h3>Personalized Training Programs</h3>
                <p>
                  This will help your dog recognize and respond appropriately to
                  your commands.
                </p>
              </div>
            </div>
            <div className="aligned-item flex pushed">
              <img src={dog6} alt="Dog Head" />
              <div>
                <h3>Off-leash Behaviour</h3>
                <p>
                  This will help your dog develop greater analytical and social
                  skills and become averse to their surrounding.
                </p>
              </div>
            </div>
            <div className="aligned-item flex pushed">
              <img src={dog2} alt="Dog Head" />
              <div>
                <h3>Dog Handlers Training</h3>
                <p>
                  This aims to make your dog less likely to bite when put in the
                  situation of being touched unexpectedly.
                </p>
              </div>
            </div>
            <div className="aligned-item flex">
              <img src={dog1} alt="Dog Head" />
              <div>
                <h3>Explosive Detection</h3>
                <p>This is meant to train your dog to sniff out explosives.</p>
              </div>
            </div>
          </div>
        </Slide>
      </div>
      <div className="section-footer buttons-container flex justify-center">
        <a href="/price-list" className="button boldTitle subcta-yellow">
          See Our Charges
        </a>
      </div>
    </div>
  );
};

export default Services;
