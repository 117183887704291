import React from "react";
import { Helmet } from "react-helmet";
import PageHero from "../components/reusables/hero/PageHero";
import ShopBody from "../components/shop/ShopBody";

export default function Shop() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.ghk9academy.com/shop" />
        <meta name="description" content="Your Home of Trained Dogs" />
        <meta property="og:description" content="Your Home of Trained Dogs" />
        <meta
          property="og:title"
          content="Academy Shop - Great Habib K9 Academy"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1703154000/banner_i6dkvh.jpg"
        />

        <meta
          name="twitter:title"
          content="Academy Shop - Great Habib K9 Academy"
        />
        <meta
          name="twitter:text:title"
          content="Academy Shop - Great Habib K9 Academy"
        />
        <meta name="twitter:description" content="Your Home of Trained Dogs" />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1703154000/banner_i6dkvh.jpg"
        />
        <title>Academy Shop - Great Habib K9 Academy</title>
      </Helmet>
      <PageHero
        classes="doggy-bg"
        titleClasses="title uppercase yellow"
        title="Academy Shop"
      />
      <ShopBody />
    </>
  );
}
