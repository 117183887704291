import React from "react";
import { Slide } from "react-reveal";
import smalldog from "../../assets/images/smallimages/dog.png";
import PartnerCard from "../reusables/PartnerCard";
import p1 from "../../assets/images/partners/p1.png";
import p2 from "../../assets/images/partners/p2.png";
import p3 from "../../assets/images/partners/p3.png";
import p4 from "../../assets/images/partners/p4.jpg";
import p5 from "../../assets/images/partners/p5.png";
import p6 from "../../assets/images/partners/p6.png";
import p7 from "../../assets/images/partners/p7.png";
import p8 from "../../assets/images/partners/p8.png";
import p9 from "../../assets/images/partners/p9.png";
import p10 from "../../assets/images/partners/p10.png";
import p11 from "../../assets/images/partners/p11.jpg";
import p12 from "../../assets/images/partners/ap12.png";
import p13 from "../../assets/images/partners/ntv.webp";
import p14 from "../../assets/images/partners/ugp.png";

const Partners = () => {
  return (
    <section className="section section-spacing-100 white-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="width-600 margin-auto center">
              <Slide top>
                <div className="section-head">
                  <h2 className="section-title dark">Our Key Partners</h2>
                  <img
                    src={smalldog}
                    alt="Small Dog"
                    className="small-image auto"
                  />
                </div>
              </Slide>
            </div>
            <div className="section-body section-spacing-20">
              <div className="partners-container">
                <PartnerCard plogo={p14} partner="Uganda Police" />
                <PartnerCard plogo={p13} partner="NTV Uganda" />

                <PartnerCard plogo={p1} partner="GREAT ESTATES INTERNATIONAL" />
                <PartnerCard
                  plogo={p2}
                  partner="GREAT HABIB CONSTRUCTION INTERNATIONAL"
                />
                <PartnerCard
                  plogo={p3}
                  partner="GREAT HABIB IMPORTS &amp; EXPORTS"
                />
                <PartnerCard plogo={p4} partner="LABOUR WORLD ACADEMY" />
                <PartnerCard plogo={p5} partner="GREAT HABIB SMART FARM" />
                <PartnerCard plogo={p6} partner="LABOUR WORLD CONNECT" />
                <PartnerCard plogo={p7} partner="GREAT HABIB WELNESS RESORT" />
                <PartnerCard plogo={p8} partner="Great Habibs Restaurant" />
                <PartnerCard plogo={p9} partner="GREAT HABIB DUCKS" />
                <PartnerCard plogo={p10} partner="MIHASOFT" />
                <PartnerCard plogo={p11} partner="MIGADDE HABIB FOUNDATION" />
                <PartnerCard plogo={p12} partner="GREAT HABIB GROUP" />
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Partners;
