import React from "react";
import { Slide } from "react-reveal";
import d1 from "../../assets/images/gallery/4.jpg";
import equip from "../../assets/images/smallimages/07.png";
import prof from "../../assets/images/smallimages/08.png";
import hap from "../../assets/images/smallimages/09.png";
import rate from "../../assets/images/smallimages/10.png";

const WhyUs = () => {
  return (
    <section className="section dogs-bg">
      <div className="section-content">
        <div className="why-container flex">
          <div className="why-image-side">
            <img src={d1} alt="Dog" />
          </div>
          <div className="why-text-side">
            <p className="section-name boldTitle dark-yellow center">
              WHY PET OWNERS
            </p>
            <Slide top>
              <div className="section-head center">
                <h2 className="section-title light-green">CHOOSE US</h2>
              </div>
            </Slide>
            <div className="section-body">
              <ul className="why-list-container">
                <li>
                  <img src={equip} alt="Icon" />
                  <div>
                    <h3>Modern Equipments</h3>
                    <p>
                      To ensure your pet gets the best quality of life with us
                    </p>
                  </div>
                </li>
                <li>
                  <img src={prof} alt="Icon" />
                  <div>
                    <h3>Professional Cynologists</h3>
                    <p>
                      We're not just passionate about dogs but also qualified
                    </p>
                  </div>
                </li>
                <li>
                  <img src={hap} alt="Icon" />
                  <div>
                    <h3>Happy Pets</h3>
                    <p>
                      Dogs love it here. Come see for yourself to prove that.
                    </p>
                  </div>
                </li>
                <li>
                  <img src={rate} alt="Icon" />
                  <div>
                    <h3>Highly rated and recommended</h3>
                    <p>Dog owners rate us and Vet doctors recommend us.</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
