import React from "react";

const ResourceCard = (props) => {
  return (
    <li className="reso-card">
      <h3>{props.filename}</h3>
      <div>{props.children}</div>
      <a
        href={props.file}
        download
        target="_blank"
        rel="noopener noreferrer"
        className="button"
      >
        Download
      </a>
    </li>
  );
};

export default ResourceCard;
