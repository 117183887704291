import React from "react";

const TeamCard = (props) => {
  return (
    <li>
      <figure>
        <img
          src={props.pic}
          alt="Great Habib Smart Farm Academy Team"
          className="box-shadow"
        />
        <figcaption className="margin-top-10">
          <p className=" boldTitle">{props.name}</p>
          <p className="team-title">{props.title}</p>
        </figcaption>
      </figure>
    </li>
  );
};

export default TeamCard;
