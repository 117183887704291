import React from "react";

const PartnerCard = (props) => {
  return (
    <figure className="partner-card">
      <img src={props.plogo} alt={props.partner} />
      <figcaption>{props.partner}</figcaption>
    </figure>
  );
};

export default PartnerCard;
