import React from "react";

const PricesBody = () => {
  return (
    <section>
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="fees-tables-container">
              <div className="tuition fees-table-item">
                <h3 className="dark uppercase section-spacing-20 center">
                  K9 Price List &amp; Charges
                </h3>
                <table className="box-shadow tution-table fees-table">
                  <tr>
                    <th>DOG TRAINING COURSES</th>
                    <th>PERIOD</th>
                    <th>FEES (Ugx)</th>
                  </tr>
                  <tr>
                    <td>Super obedience</td>
                    <td>2 MONTHS</td>
                    <td>2,000,000</td>
                  </tr>
                  <tr>
                    <td>Guarding</td>
                    <td>1 MONTH</td>
                    <td>850,000</td>
                  </tr>
                  <tr>
                    <td>Poison Escape</td>
                    <td>1 MONTH</td>
                    <td>550,000</td>
                  </tr>
                  <tr>
                    <td>Socialisation</td>
                    <td>1 MONTH</td>
                    <td>550,000</td>
                  </tr>
                  <tr>
                    <td>Basic obedience &amp; Guarding</td>
                    <td>2 MONTHS</td>
                    <td>1,550,000</td>
                  </tr>
                  <tr>
                    <td>Dog Boarding (Big dogs)</td>
                    <td>1 DAY</td>
                    <td>50,000</td>
                  </tr>
                  <tr>
                    <td>Dog Boarding (Small dogs)</td>
                    <td>1 DAY</td>
                    <td>30,000</td>
                  </tr>
                  <tr>
                    <td>House Training Small House Dogs</td>
                    <td>2-3 MONTHS</td>
                    <td>1,550,000</td>
                  </tr>
                  <tr>
                    <td>Grooming (Small dogs)</td>
                    <td>...</td>
                    <td>80,000</td>
                  </tr>
                  <tr>
                    <td>Grooming (Big dogs)</td>
                    <td>...</td>
                    <td>100,000</td>
                  </tr>
                  <tr>
                    <td>Consultation</td>
                    <td>...</td>
                    <td>50,000</td>
                  </tr>
                  <tr>
                    <td>Assessment</td>
                    <td>...</td>
                    <td>100,000</td>
                  </tr>
                  <tr>
                    <td>Kennel Site Visit</td>
                    <td>...</td>
                    <td>50,000</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricesBody;
