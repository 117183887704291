import React from "react";
import PdtCard from "../reusables/PdtCard";

import dogfood from "../../assets/images/content/dogfood.webp";
import dogshampoo from "../../assets/images/content/dhsampoo.png";

const ShopBody = () => {
  return (
    <section className="section section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content width-960 margin-auto">
            <div className="pdts-cards-container flex">
              <PdtCard
                pdtImg={dogfood}
                pdtName="Dog Food"
                pdtLink="#"
              />
              <PdtCard
                pdtImg={dogshampoo}
                pdtName="Dog Shampoo"
                pdtLink="#"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ShopBody;
