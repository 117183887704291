import React from "react";
import { Slide } from "react-reveal";
import bull from "../../assets/images/icons/bull.svg";
import eye from "../../assets/images/icons/eye.svg";
import Mission from "../reusables/Mission";
import Team from "../reusables/Team";

const AboutBody = () => {
  return (
    <section className="section section-spacing-50 no-padding-bottom cloudy-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="textpage-container">
              <div className="textpage-inner section-spacing-50 no-padding-top flex">
                <div className="textpage-text-side">
                  <h2 className=" light-green">About Us</h2>
                  <div className="textpage-text-body">
                    <p>
                      We are Great Habib K9 Academy, the home of trained dogs.
                    </p>
                    <p>
                      We are passionate about nurturing well-behaved and happy
                      dogs while strengthening the bond between pets and their
                      owners. Our journey began with a shared love for dogs and
                      a commitment to providing exceptional canine education and
                      behavior modification services.
                    </p>
                    <p>
                      Our Mission is to inspire and empower a community of
                      responsible dog owners by offering comprehensive,
                      expert-led training programs, breeding and Accommodation.
                    </p>
                    <p>
                      Our Vision is to be the World leading Center for Canine
                      Research, training and development.
                    </p>
                    <h3>Expert Instructors</h3>
                    <p>
                      Our academy is staffed with a team of expert trainers who
                      are dedicated to the well-being of dogs and the
                      satisfaction of their owners. Our trainers bring years of
                      experience, a deep understanding of canine behavior, and a
                      passion for helping dogs reach their full potential.
                    </p>
                    <h3>Comprehensive Training Programs</h3>
                    <p>
                      We offer a wide range of training programs tailored to
                      meet the unique needs of each dog and owner. From basic
                      obedience training to advanced behavior modification, we
                      provide the knowledge and skills necessary for a
                      harmonious coexistence between pets and their families.
                    </p>
                    <h3>
                      Positive Reinforcement at Great Habib K9 Dog Training
                      Academy
                    </h3>
                    <p>
                      We believe in the power of positive reinforcement. Our
                      training methods focus on rewarding good behavior,
                      creating a nurturing and enjoyable learning experience for
                      dogs. We do not support harsh or punitive training
                      techniques.
                    </p>
                    <h3>Commitment to Ethical Training</h3>
                    <p>
                      Ethical training practices are at the core of our
                      philosophy. We treat every dog with respect and
                      compassion, ensuring their well-being is our top priority.
                      Our academy adheres to the highest standards of animal
                      welfare and professional conduct.
                    </p>

                    <h3>Our Dog Breeds</h3>
                    <p>
                      We train and groom over 8 breeds of dogs namely;
                      ROTTWEILER, SWISS GERMAN SHEPHERD, MALTESE, BELGIAN
                      MALINOIS, A DACHSHUND, DOBERMAN, BEAGLE, DUTCH SHEPHERD
                      and GERMAN SHEPHERD.
                    </p>
                    <h3>Our Services</h3>
                    <p>
                      We do Boarding &amp; Grooming, Agility Training, Basic
                      Obidience Training, Behaviour Modification Training,
                      Personalized Training Programs, Off-leash Behaviour, Dog
                      Handlers Training and Train your dog to detect explosives.
                    </p>

                    <h3>Location &amp; Contacts</h3>
                    <p>
                      We are located in Seeta, Kasangati, Kampala Uganda for a
                      visit from Monday to Sunday starting at 8AM to 9PM. You
                      can always give us a call or WhastApp on +256 (742) 999
                      000 or send us an email contact@ghk9academy.com.
                    </p>
                  </div>
                  <div className="textpage-text-model boldTitle uppercase">
                    <p>Great Habib K9 Academy, The Home of Your Trained Dog.</p>
                  </div>
                </div>
                <Mission />
              </div>
            </div>

            <Team />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBody;
