import React from "react";
import { Slide } from "react-reveal";

const GreatHabibResults = () => {
  return (
    <section className="section section-spacing-100 results">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="about-results-container">
              <div className="about-results-inner flex">
                <Slide left>
                  <div className="about-results-item">
                    <h5>500+</h5>
                    <p>Happy Dog Owners</p>
                  </div>
                </Slide>
                <Slide top>
                  <div className="about-results-item">
                    <h5>99%</h5>
                    <p>Satisfaction Rate</p>
                  </div>
                </Slide>
                <Slide right>
                  <div className="about-results-item">
                    <h5>8</h5>
                    <p>Dog Breeds</p>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GreatHabibResults;
