import React from "react";
import Services from "../reusables/Services";

const ServicesBody = () => {
  return (
    <section className="section section-spacing-100 dog-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <Services />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicesBody;
