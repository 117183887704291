import React from "react";

const BreedCard = (props) => {
  return (
    <div className="breed-card">
      <img src={props.img} alt={props.breed} className="box-shadow"/>
      <h3>{props.breed}</h3>
      <div className="desc">{props.children}</div>

      <h4>Roles:</h4>
      <p>{props.roles}</p>
    </div>
  );
};

export default BreedCard;
