import React from "react";
import { Slide } from "react-reveal";
import d10 from "../../assets/images/gallery/7.jpg";
import d6 from "../../assets/images/gallery/23.jpeg";
import check from "../../assets/images/icons/check.svg";

const AboutHome = () => {
  return (
    <section className="section section-spacing-100 whitedot-bg">
      <div className="container">
        <div className="large">
          <div className="section-content">
            <div className="about-contents relative flex">
              <div className="about-image-side flex">
                <Slide top>
                  <img src={d10} className="big-img about-img" alt="Dog" />
                </Slide>
                <Slide bottom>
                  <img src={d6} className="small-img about-img" alt="Dog" />
                </Slide>
              </div>
              <div className="about-text-side">
                <p className="section-name boldTitle dark-yellow">ABOUT US</p>
                <Slide top>
                  <div className="section-head">
                    <h2 className="section-title light-green">
                      Effective dog training, real behavior solutions
                    </h2>
                    <p className="section-subtitle margin-top-bottom-10">
                      We are passionate about nurturing well-behaved and happy
                      dogs while strengthening the bond between pets and their
                      owners.
                    </p>
                  </div>
                </Slide>
                <div className="section-body">
                  <ul className="list-body-items">
                    <li>
                      <img src={check} alt="Tick" />
                      <span>Develop a clear and loving relationship</span>
                    </li>
                    <li>
                      {" "}
                      <img src={check} alt="Tick" />
                      <span>Be able to communicate with your dog</span>
                    </li>
                    <li>
                      {" "}
                      <img src={check} alt="Tick" />
                      <span>Train it to always be eager to please</span>
                    </li>
                    <li>
                      {" "}
                      <img src={check} alt="Tick" />
                      <span>Create a golden relationship with your dog</span>
                    </li>
                  </ul>
                </div>
                <div className="section-footer buttons-container flex justify-center section-spacing-20 no-padding-bottom">
                  <a
                    href="https://form.jotform.com/ghk9academy/animal-enrollment-form"
                    className="button boldTitle subcta-yellow"
                  >
                    Enroll Your Dog
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHome;
