import React from "react";
import ResourceCard from "../reusables/ResourceCard";
import f1 from "../../assets/files/BOARD-ADMISSION-FORM.pdf";
import f2 from "../../assets/files/K9-ACADEMY-PRICE-LIST.pdf";
import f3 from "../../assets/files/OWNER-RELEASE-FORM.pdf";

const ResourcesBody = () => {
  return (
    <section className="section-spacing-80">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="section-body">
                <ul className="reso-container">
                  <ResourceCard filename="board admission form" file={f1}>
                    <p>
                      This form is to give us details about the pet you want to
                      enroll with us.
                    </p>
                    <p>
                      Please download it, fill it and then take a screenshot or
                      scan it.
                    </p>
                    <p>
                      You will submit it to us on enrolling your pet through our{" "}
                      <a
                        href="https://form.jotform.com/ghk9academy/animal-enrollment-form"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        form
                      </a>
                    </p>
                  </ResourceCard>
                  <ResourceCard filename="owner release form" file={f2}>
                    <p>
                      This form of consent from you releasing your pet to us.
                    </p>
                    <p>Please download it, fill it.</p>
                    <p>
                      You will come along with it when delivering your pet or
                      when it is picked by our team.
                    </p>
                  </ResourceCard>
                  <ResourceCard filename="K9 Price List" file={f3}>
                    <p>
                      This document contains our fees for all our services.
                    </p>
                    <p>You can download it and look through.</p>
                    <p>
                      Thank You!
                    </p>
                  </ResourceCard>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResourcesBody;
